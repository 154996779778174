<template>
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  EXam
</button>
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#condition">
  Condition
</button>
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#get-otp">
  Send OTP
</button> -->

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Pricing</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown link
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav> -->

<main class="bg-showroom">
    <section id="booking" class="animsition">
        <div class="container pt-5 mb-5">
            <div class="row">
                <div class="col-12">
                    <h3 class="heading text-center">รายละเอียดลูกค้า</h3>
                    <h4 class="text-darkred text-center subheading">(กรุณากรอกรายละเอียดให้ถูกต้อง)</h4>
                </div>
                <div class="col-lg-6 offset-lg-3 col-12 mb-5">
                    <!-- <form> -->
                        <div class="fields fullname-field">
                            <div class="form__group field title-field">
                                <select  class="select2" name="title" id="title" style="width: 100%;" >
                                    <option value="">คำนำหน้า</option>
                                    <option>นาย</option>
                                    <option>นาง</option>
                                    <option>นางสาว</option>
                                </select>
                            </div>
                            <div class="form__group field firstname-field">
                                <input id="fname" type="text" class="form__field" placeholder="ชื่อ" name="name" autocomplete="off" v-model="i_firstname"/>
                            </div>
                            <div class="form__group field lastname-field">
                                <input id="lname" type="text" class="form__field" placeholder="นามสกุล" name="lastname" autocomplete="off" v-model="i_lastname"/>
                            </div>
                        </div>
                        <div class="fields">
                          <div class="form__group field">
                            <input id="tel" type="text" class="form__field" placeholder="หมายเลขโทรศัพท์มือถือ" name="phone" autocomplete="off" v-model="i_phone" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').slice(0, 10);"/>
                          </div>
                        </div>
                        <div class="fields">
                            <div class="form__group field">
                                <input id="email" type="text" class="form__field" placeholder="อีเมล์" name="อีเมล ที่สามารถรับรายละเอียดและเงื่อนไขการใช้คูปอง" autocomplete="off" v-model="i_email"/>
                            </div>
                        </div>
                        <div class="fields" id="model-BG">
                            <div class="form__group field">
                              <select name="" id="car-model" class="form__field select2" style="height:50px" v-model="i_car_model">
                                <option value="">car model</option>
                                <option value="">XXXX</option>
                                <option value="">CARR</option>
                              </select>
                            </div>
                          </div>
                          <div class="fields">
                            <div class="form__group field">
                              <input id="license" type="text" class="form__field" placeholder="หมายเลขทะเบียนรถ" name="phone" autocomplete="off" v-model="i_license_plate">
                            </div>
                          </div>
                          <div class="fields">
                            <div class="form__group field">
                              <input id="vin" type="text" class="form__field" placeholder="หมายเลขตัวถังรถ หรือ VIN No." name="phone" autocomplete="off" v-model="i_vinno">
                            </div>
                          </div>
                        <div class="form-check acceptance-field d-flex align-items-center">
                            <div  class="checkbox-wrap"><input id="chk" type="checkbox" name="" disabled></div>
                            <label id="conditionBT" class="form-check-label"><a href="javascript:;"  style="color:#000000;">ยอมรับเงื่อนไข (กรุณาอ่านรายละเอียดเงื่อนไขให้ครบถ้วน จึงจะกดยอมรับได้)</a></label>
                            <!-- <label id="conditionBT" class="form-check-label" data-bs-toggle="modal" data-bs-target="#condition"><a href="javascript:;"  style="color:#000000;">ยอมรับเงื่อนไข (กรุณาอ่านรายละเอียดเงื่อนไขให้ครบถ้วน จึงจะกดยอมรับได้)</a></label> -->
                          </div>
                        <div class="breakline"></div>
                        <div class="text-center red-txt" id="errOTP"></div>
                        <div class="btn-wrapper btn-otp">
                            <!--<a class="cta" href="javascript:;"><span>กดรับ OTP เพื่อยืนยันตัวตน</span></a>-->
<!--                            <button id="sendOTP" type="button" class="cta" data-bs-toggle="modal" data-bs-target="#get-otp" v-on:click="sendOTP()"><span>กดรับ OTP เพื่อยืนยันตัวตน</span></button>-->
                            <button id="sendOTP" type="button" class="cta"><span>กดรับ OTP เพื่อยืนยันตัวตน</span></button>
                            <!-- <button type="button" class="cta" v-on:click="$router.push('success')"><span>goto success</span></button> -->
                        </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </section>

<!-- ========================================= -->

    <div class="modal modal-condition" id="condition" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- <div class="close-wrap"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">X</span></button></div> -->
                <div class="modal-body text-center">
                    <h5>Term & Condition</h5>
                    <div id="con-d" class="condition-detail text-left">
                        <p><span>เงื่อนไขของคูปอง</span></p>
                        <p>1.	คูปองมีมูลค่า 300 บาท เพื่อใช้เป็นส่วนลดเมื่อมาใช้บริการ ณ ศูนย์บริการเอ็มจีทั่วประเทศ สำหรับงานบริการเช็กระยะ, งานซ่อมทั่วไป, รวมถึงการซื้ออุปกรณ์ตกแต่ง หรือสินค้าที่ระลึก (ไม่รวมงานบริการซ่อมสี และตัวถัง) ที่มีค่าใช้จ่ายขั้นต่ำ 2,000 บาท รวมภาษีมูลค่าเพิ่มต่อ 1 ใบเสร็จรับเงิน</p>
                        <p>2.	คูปองมีมูลค่า 300 บาท เพื่อใช้เป็นส่วนลดสำหรับบริการนอกสถานที่ Mobile Service สำหรับงานบริการเช็กระยะ งานเปลี่ยนอะไหล่พื้นฐาน เช่น แบตเตอรี่, ใบปัดน้ำฝน, หลอดไฟต่างๆ รวมถึงการซื้ออุปกรณ์ตกแต่ง หรือสินค้าที่ระลึกผ่านทางบริการ Mobile Service โดยยอดการใช้จ่ายต้องไม่ต่ำกว่ามูลค่าของคูปอง</p>
                        <p>3.	คูปองนี้สามารถใช้ได้ตั้งแต่วันที่ 1 กันยายน พ.ศ. 2564 ถึง วันที่ 31 ธันวาคม พ.ศ. 2564</p>
                        <p>4.	กรณีลูกค้าต้องการใช้บริการ Mobile Service กรุณาทำการนัดหมายล่วงหน้ากับศูนย์บริการที่ท่านต้องการเข้ารับบริการ</p>
                        <p>5.	คูปองนี้ใช้ได้กับรถที่ทำการลงทะเบียนในระบบ MG Passion Service Online Shop เท่านั้น</p>
                        <p>6.	คูปองสามารถใช้พร้อมกับแคมเปญอื่นๆ ของทางศูนย์บริการที่มีอยู่ขณะเข้ารับบริการได้ ยกเว้นการใช้พร้อมกับคูปอง Fast Service</p>
                        <p>7.	ลูกค้าต้องแสดงคูปองโค้ดให้แก่พนักงาน ก่อนเข้ารับบริการ</p>
                        <p>8.	คูปองนี้ไม่สามารถแลกเปลี่ยน หรือทอนเป็นเงินสดได้</p>
                        <p>9.	บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</p>

                        <p><span>เงื่อนไข และนโยบายความเป็นส่วนตัว</span></p>
                        <p>นโยบายความเป็นส่วนตัวออกแบบมาเพื่อช่วยให้คุณ เข้าใจทางเลือกเกี่ยวกับความเป็นส่วนตัวของคุณเมื่อใช้เว็บไซต์ และบริการต่างๆ ของ บริษัท เอ็มจี เซลส์ (ประเทศไทย) จำกัด เพื่อให้ความยินยอมแก่บริษัทฯ ในการดำเนินการจัดเก็บ ประมวลผล หรือนำข้อมูลส่วนบุคคลไปใช้ตามวัตถุประสงค์ อีกทั้งเจ้าของข้อมูลมีสิทธิ ดังนี้</p>
                        <p style="text-indent: 10%;">1. เพื่อใช้ในด้านการตลาด และการติดต่อสื่อสาร เพื่อทำการตลาด การทำตลาดซ้ำ การสื่อสาร การขาย การเสนอ ข้อเสนอพิเศษ การเสนอโปรโมชัน การแจ้งเตือน การแจ้งข่าวสาร และข้อมูลเกี่ยวกับผลิตภัณฑ์ บริการรวมถึงบริการหลังการขายจาก “เอ็มจี” บริษัทในกลุ่ม บริษัทย่อย บริษัทในเครือ ผู้จำหน่ายที่ได้รับการแต่งตั้ง และ/หรือ พันธมิตรทางธุรกิจ สถาบันการเงิน บริษัทประกันภัย เพื่อประกอบธุรกิจ พัฒนาผลิตภัณฑ์ และบริการที่เกี่ยวข้องกับธุรกิจของ “เอ็มจี” ในกรณีที่ไม่สามารถนำไปใช้ตามฐานกฎหมายอื่นได้</p>
                        <p style="text-indent: 10%;">2. เพื่อวัตถุประสงค์ในการจองรถยนต์บริษัท เอ็มจี เซลส์ (ประเทศไทย) จำกัด และ/หรือ การเสนอบริการอื่นใดที่เกี่ยวเนื่องกับรถยนต์เอ็มจี หรือผู้จำหน่ายรถยนต์เอ็มจีอย่างเป็นทางการ และบริษัท เอ็มจี เซลส์ (ประเทศไทย) จำกัด ทั้งนี้ผู้จองตกลงยินยอมให้เปิดเผยข้อมูลส่วนบุคคลดังกล่าวให้แก่บุคคลภายนอก และ/หรือ สถาบันการเงิน บริษัทประกันภัย เพื่อการดำเนินการทางด้านสินเชื่อเช่า - ซื้อ</p>
                        <p style="text-indent: 10%;">3. สำหรับข้อมูลส่วนบุคคลที่ละเอียดอ่อน เพื่อเก็บรวบรวม ใช้ เก็บรักษา เปิดเผย และ/หรือ โอนไปยังต่างประเทศ ซึ่งข้อมูลส่วนบุคคลที่ละเอียดอ่อนของลูกค้า เพื่อวัตถุประสงค์ต่าง ๆ ตามที่กฎหมายกำหนดให้ต้องได้รับความยินยอม นอกจากนี้ลูกค้ายังมีสิทธิที่จะถอนความยินยอมได้ แต่การถอนความยินยอมจะไม่กระทบต่อความชอบด้วยกฎหมายของการเก็บรวบรวมใช้ และเปิดเผยข้อมูลส่วนบุคคลของลูกค้าที่ “เอ็มจี” ได้มาโดยอาศัยความยินยอมของลูกค้าก่อนที่จะถอนความยินยอม</p>
                        <p style="text-indent: 10%;">4. เพื่อปกป้องสิทธิประโยชน์ของ “เอ็มจี” ปกป้องสิทธิ ทรัพย์สิน ความปลอดภัย หรือการดำเนินการของ “เอ็มจี” หรือของบริษัทในกลุ่ม บริษัทย่อย บริษัทในเครือ หรือลูกค้า หรือบุคคลอื่นๆ</p>
                        <p style="text-indent: 10%;">5. ผู้จำหน่าย และ/หรือ ตัวแทนขายที่ได้รับการแต่งตั้ง ในบางครั้ง “เอ็มจี” อาจเปิดเผยข้อมูล และได้รับข้อมูลส่วนบุคคลจากผู้จำหน่ายที่ได้รับการแต่งตั้งที่ลูกค้าเลือก หรืออยู่ใกล้กับลูกค้า เพื่อให้บริการแก่ลูกค้า</p>
                        <p style="text-indent: 10%;">6. ที่ปรึกษาจากภายนอก ซึ่งรวมถึงทนายความ ช่าง และผู้ตรวจสอบบัญชีซึ่งช่วยเหลือในการดำเนินธุรกิจของ “เอ็มจี” หรือการใช้สิทธิเรียกร้องตามกฎหมาย</p>
                        <p style="text-indent: 5%;">7. บุคคลที่สามตามที่กฎหมายกำหนด เพื่อปฏิบัติตามกฎหมาย หรือกฎระเบียบ ซึ่งรวมถึงหน่วยงานบังคับใช้กฎหมายศาล ผู้บังคับใช้กฎหมาย หน่วยงานรัฐบาล หรือบุคคลที่สามอื่นๆ หากมีความจำเป็นจะต้องปฏิบัติตามภาระผูกพันตามกฎหมาย หรือกฎระเบียบ</p>
                        <p style="text-indent: 5%;">8. โรงพยาบาล และหน่วยกู้ภัย อาจมีการเปิดเผยข้อมูลส่วนบุคคลของลูกค้าในกรณีฉุกเฉิน เพื่อปกป้องผลประโยชน์ของลูกค้า</p>
                        <p style="text-indent: 5%;">9. แหล่งข้อมูลอื่นๆ ที่เปิดเผยต่อสาธารณะ เช่น เว็บไซต์ โฆษณา และ/หรือ แพลตฟอร์มโซเชียล</p>
                        <p style="text-indent: 5%;">10. เจ้าของข้อมูลส่วนบุคคลมีสิทธิดังต่อไปนี้</p>
                        <p style="text-indent: 10%;">10.1 ขอเข้าถึงข้อมูล และขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับตนได้</p>
                        <p style="text-indent: 10%;">10.2 ขอให้เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอมได้</p>
                        <p style="text-indent: 10%;">10.3 แจ้งให้ดำเนินการแก้ไข เพิ่มเติม เปลี่ยนแปลง ให้ข้อมูลส่วนบุคคลนั้น ถูกต้องเป็นปัจจุบัน สมบูรณ์ ไม่ก่อให้เกิดความเข้าใจผิดได้</p>
                        <p style="text-indent: 10%;">10.4 คัดค้านการเก็บรวบรวมใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตนที่กฎหมายอนุญาตให้เก็บได้โดยไม่ต้องขอความยินยอมจากเจ้าของข้อมูลส่วนบุคคลเมื่อใดก็ได้</p>
                        <p style="text-indent: 10%;">10.5 ขอให้ทำลาย หรือทำให้ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลได้</p>
                        <p style="text-indent: 10%;">10.6 เพิกถอนความยินยอมในการเก็บ รวบรวม ใช้ เปิดเผย ซึ่งข้อมูลส่วนบุคคลของตนได้</p>
                        <br/>
                        <p style="text-indent: 5%;">อนึ่ง ก่อนให้ความยินยอมข้าพเจ้าได้อ่าน และเข้าใจข้อกำหนด และเงื่อนไขในการเปิดเผยข้อมูลส่วนบุคคลซึ่งระบุไว้ด้านบนโดยละเอียดแล้ว จึงได้ยินยอมในการให้ข้อมูลไว้ในระบบของเว็บไซต์</p>
                        <br/>
                        <p style="text-indent: 5%;">ข้าพเจ้าได้อ่าน และเข้าใจในเงื่อนไข และข้อตกลงข้างต้นเป็นที่เรียบร้อยแล้ว</p>
                    </div>
                </div>
                <div class="d-flex justify-content-aroung" style="margin-top: 18px;">
                    <button id="cancleBT" class="btn btn-outline-danger cBT mr10 outlineR closeP"  data-bs-dismiss="modal" aria-label="Close">ยกเลิก</button>
                    <button id="acceptBT" class="btn btn-danger cBT ml10 acpBT" disabled data-bs-dismiss="modal" aria-label="Close">ยอมรับ</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal center get-otp" id="get-otp" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-vertical-centered" role="document">
            <div class="modal-content">
                <div class="close-wrap"><button type="button" class="close xBT" data-bs-dismiss="modal" aria-label="Close" style="padding-left: 3px !important;"><span aria-hidden="true">X</span></button></div>
                <div class="modal-body text-center">
                      <h5>MG ONLINE BOOKING</h5>
                      <h5 class="text-red">Code : xxxxxx</h5>
                      <p>ใส่รหัส OTP ที่ได้รับจากโทรศัพท์มือถือของคุณ</p>
                      <p class="remaining-time">05:00 min</p>
                      
                    <!-- <form name=""> -->
                        <div class="fields">
                            <div class="form__group field">
                                <input id="pin" type="text" class="form__field" placeholder="XXXXXX" name="" autocomplete="off" v-model="i_otp_input"/>
                            </div>
                            <div class="form__group field text-right">
                            <a href="#" class="resend-otp">Resend OTP</a>
                            </div>
                        </div>
                        <div class="text-center red-txt" id="alerttxt"></div>
                        <div id="nextBT" class="btn-wrapper">
<!--                            <a class="cta" href="javascript:void(0)"  data-bs-dismiss="modal" aria-label="Close" v-on:click="$router.push('success')"><span>ตกลง</span></a>-->
                            <a class="cta" href="javascript:void(0)"  data-bs-dismiss="modal" aria-label="Close"><span>ตกลง</span></a>
                        </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
  <div class="modal center get-otp error" id="error-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-vertical-centered" role="document">
      <div class="modal-content">
        <div class="close-wrap"><button type="button" class="close xBT" data-bs-dismiss="modal" aria-label="Close" style="padding-left: 3px !important;"><span aria-hidden="true">X</span></button></div>
        <div class="modal-body text-center">
          <p id="err_text"></p>

<!--          <div class="text-center red-txt" id="alerttxt"></div>-->
          <div class="btn-wrapper">
            <!--                            <a class="cta" href="javascript:void(0)"  data-bs-dismiss="modal" aria-label="Close" v-on:click="$router.push('success')"><span>ตกลง</span></a>-->
            <a class="cta" href="javascript:void(0)"  data-bs-dismiss="modal" aria-label="Close" ><span>ตกลง</span></a>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>

</main>
</template>

<!-- 
<template src="./Register.html"></template>
-->
<style src="../../assets/styles/custom.css" scoped lang="css"></style>
<style src="../../assets/styles/style.css" scoped lang="css"></style>
<script>

// import '@/assets/styles/custom.css';
// import '@/assets/lib/select2/select2.min.css';
// import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"

// import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
// import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
// import '@/assets/lib/bootstrap/bootstrap.min.js';
// import '@/assets/lib/select2/select2.min.js';

import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import Vue from 'vue';
import axios from "axios";
import {IsEmail, IsPhoneNumber, isString, MinLength} from "class-validator";


export default {
  // car_models: [1,2,3],
  name: 'Register',
  components: {},
  async data() {
    return {
      car_models: [],
      i_name_title: null,
      i_firstname: null,
      i_lastname: null,
      i_email: null,
      i_phone: null,
      i_car_model: null,
      i_license_plate: null,
      i_vinno: null,
      i_otp_input: null,
      otp_token: null
    }
  },
  async mounted() {
    let token
    var timemer
    $(document).ready(function () {
      $('.select2').select2({minimumResultsForSearch: -1});
      // if($('span.select2-selection__arrow').length > 0) {
      //     let span = $("span.select2-selection__arrow")[0];
      //     span.parentNode.removeChild(span);
      // }
      // $('#booking > div > div > div.col-lg-6.offset-lg-3.col-12.mb-5 > div.fields.fullname-field > div.form__group.field.title-field > span > span.selection > span').append('<span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>')
    });

    $('#nextBT').on('click', async (evt) => {
      let res = await verifyOTP()
      if (res.header.res_code == 200) {
        let sendResult = await sendRegisterData();
        if(sendResult.header.res_code == 200) {
          $('#get-otp').css({'display': 'none'})
          this.$router.push('success')
        } else {
          // $('#get-otp').css({'display': 'none'})
          // showError(sendResult.header.res_desc)
          this.$router.push('fail-coupon')
        }
      } else {
        $('#alerttxt').html(`${res.header.res_desc}`)
      }
    })

    $('#conditionBT').on('click', function (event) {
      console.log("LL")
        // $("#condition").modal('show')
        $("#condition").css({'display':'block'}).addClass('show')
        $('body').append(`<div class="modal-backdrop show"></div>`)
    })
    $('#cancleBT').on('click',function( event ) {
      $("#condition").css({'display':'none'}).removeClass('show')
      $('.modal-backdrop').remove()
    })

    $('#acceptBT').on('click',function( event ) {
      $("#chk").prop("checked", "checked")
      $("#chk").removeAttr("disabled")
      $("#condition").css({'display':'none'}).removeClass('show')
      $('.modal-backdrop').remove()

    })

    $('.closeP').on('click', async (evt) => {
      // $("#condition").modal('hide');

      $("#condition").css({'display': 'none'})
    })

    $('#sendOTP').on('click', async (evt) => {
      if (!$("#title").val()) return $("#sendOTP")[0].innerHTML = 'กรุณาเลือก คำนำหน้าชื่อ'
      if (!$("#fname").val()) return $("#sendOTP")[0].innerHTML = 'กรุณากรอก ชื่อ'
      if (!$("#lname").val()) return $("#sendOTP")[0].innerHTML = 'กรุณากรอก นามสกุล'
      if (!$("#email").val()) return $("#sendOTP")[0].innerHTML = 'กรุณากรอก Email'
      if (!_isEmail($("#email").val())) return $("#sendOTP")[0].innerHTML = 'email ผิดรูปแบบ'
      if (!$("#car-model").val()) return $("#sendOTP")[0].innerHTML = 'กรุณาเลือกรุ่นรถ'
      if (!$("#tel").val()) return $("#sendOTP")[0].innerHTML = 'กรุณากรอกเบอร์โทรศัพท์'
      if ($("#tel").val().length != 10) return $("#sendOTP")[0].innerHTML = 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง'
      if (!$("#license").val()) return $("#sendOTP")[0].innerHTML = 'กรุณากรอกทะเบียนรถ'
      if (!$("#vin").val()) return $("#sendOTP")[0].innerHTML = 'กรุณากรอก vin no.'

      if (!$("#chk").prop("checked")) return $("#sendOTP")[0].innerHTML = 'กรุณากด ยอมรับเงื่อนไข'

      let campaignValid = await checkCampaign();
      if(campaignValid.header.res_code == 200) {
        sendOTP();
      } else {
        if(!campaignValid.header.res_desc.includes("campaign")) {
          $('#get-otp').css({'display': 'none'})
          showError(campaignValid.header.res_desc)
        } else {
          this.$router.push('fail')
        }
      }
    })

    $('#con-d').on('scroll', function () {
      console.log($(this)[0].scrollHeight)
      if ($(this).scrollTop() + $(this).innerHeight() >= ($(this)[0].scrollHeight - 5)) {
        // alert('end reached');
        $("#sendOTP")[0].innerHTML = 'กดรับ OTP เพื่อยืนยันตัวตน'
        // $("#chk").attr("checked",true)
        $("#chk,.acpBT").attr("disabled", false)
        $("#sendOTP").attr("disabled", false)
      }
    })
    $('.resend-otp').on('click', async (evt) => {
      clearInterval(timemer);
      sendOTP();
    })
    $('.closeP').on('click', async (evt) => {
      // $("#condition").modal('hide');
      $("#condition").css({'display': 'none'})
    })
    $('.acpBT').on('click', async (evt) => {
      $("#chk").prop("checked", "checked")
      // $("#condition").modal('hide');
      $("#condition").css({'display': 'none'})
    })

    async function sendOTP() {
      $("#errOTP")[0].innerHTML = ''
      $('#pin').val('')
      if (timemer) clearInterval(timemer);
      //$("div").empty();
      $("#sendOTP")[0].innerHTML = 'กำลังส่ง OTP'
      let resOTP = await callAPIOTP()
      if (resOTP.header && resOTP.header.res_code == 200) {
        $("#sendOTP")[0].innerHTML = 'กดรับ OTP เพื่อยืนยันตัวตน'
        token = resOTP.body.data.token
        $('#get-otp').modal('show');
        // $('#get-otp').css({'display': 'block'})
        let h = 4
        let m = 59
        timemer = setInterval(() => {
          $(".remaining-time").html(`${h}:${m.length == 1 ? '0' + m : m} min`)
          m--
          if (h == 0 && m == 0) {
            h = 5
            // $('#get-otp').modal('hide');
            $('#get-otp').css({'display': 'none'})
            clearInterval(timemer);
          }
          if (m == 0) {
            m = 59
            h--;
          }
        }, 1000);
      } else {
        // $("#errOTP")[0].innerHTML = resOTP
        $("#sendOTP")[0].innerHTML = "กดรับ OTP เพื่อยืนยันตัวตน"
      }
    }
    function callAPIOTP() {
      return new Promise(async(resolve, reject) => {
        let vinnoValid = await checkVinNo();
        let campaignValid = await checkCampaign();
        if(vinnoValid.header.res_code != 200) {
          showError(vinnoValid.header.res_desc);
          $("#sendOTP")[0].innerHTML = 'กดรับ OTP เพื่อยืนยันตัวตน'
          return resolve(vinnoValid);
        }
        if(campaignValid.header.res_code != 200) {
          showError(vinnoValid.header.res_desc);
          $("#sendOTP")[0].innerHTML = 'กดรับ OTP เพื่อยืนยันตัวตน'
          return resolve(campaignValid);
        }
        let reqData = {
          msisdn: $("#tel").val(),
          trx_token: ""
        }
        let r = await axios.post(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/otp/send`, reqData, {headers: {
            "lang": sessionStorage.LANG
          }})
        return resolve(r.data);
      })
    }

    function checkVinNo() {
      return new Promise(async(resolve, reject) => {
        let reqData = {
          vin_no: $("#vin").val()
        }
        let r = await axios.post(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/vinno/validate`, reqData, {headers: {
            "lang": sessionStorage.LANG
          }})
        return resolve(r.data);
      })
    }

    function checkCampaign() {
      return new Promise(async(resolve, reject) => {
        let reqData = {
          vin_no: $("#vin").val()
        }
        let r = await axios.post(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/campaign/validate`, reqData, {headers: {
            "lang": sessionStorage.LANG
          }})
        return resolve(r.data);
      })
    }

    function sendRegisterData() {
      return new Promise(async(resolve, reject) => {
        let reqData = {
          vin_no: $("#vin").val(),
          car_type_id: $("#car-model").val(),
          email: $("#email").val(),
          firstname: $("#fname").val(),
          lastname: $("#lname").val(),
          name_title: $("#title").val(),
          license_plate: $("#license").val(),
          mobile: $("#tel").val(),
        }
        let r = await axios.post(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/register`, reqData, {headers: {
            "lang": sessionStorage.LANG
          }})
        return resolve(r.data);
      })
    }

    function showError(message) {
      $("#err_text").html(`${message}`)
      $("#error-modal").modal('show')
    }
    function verifyOTP() {
      return new Promise(async(resolve, reject) => {
        let reqData = {
          msisdn: $("#tel").val(),
          token: token,
          pin: $("#pin").val(),
          trx_token: ""
        }
        let r = await axios.post(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/otp/verify`, reqData, {headers: {
            "lang": sessionStorage.LANG
          }})
        return resolve(r.data);
      })
    }
    function _isEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }



// (function($) {
//     $.fn.hasScrollBar = function() {
//         return this.get(0).scrollHeight > this.get(0).clientHeight;
//     }
// })(jQuery);


// =============================================

  },
  async created() {
    function getCarModels() {
      // token,pin
      return new Promise(async (resolve, reject) => {
        let r = await axios.get(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/car/model/frontend`, {headers: {
            "lang": "en"
          }})
        let data = r.data;
        if (data.header.res_code == 200) {
          if (data.body.error) resolve(data.body.error.message)
          if (data.body.length) {
            $("#car-model").html('')
            $("#car-model").append(`<option value="" >ระบุรุ่นรถ</option>`)
            for (const i of data.body) {
              $("#car-model").append(`<option value="${i.type_id}">${i.name}</option>`)
            }
            return resolve(true)
          }
          return resolve(false)
        } else return resolve(data.header.res_desc)
      })
    }
    await getCarModels()
  },
  methods: {
    clickOTP() {
      $("#condition").modal('show')
    },
    updateData() {
      this.$forceUpdate();
    }
  }

}
</script>
<style src="./Register.scss" scoped lang="scss"></style>
