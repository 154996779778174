<main class="bg-showroom">
    <section id="booking" class="animsition" style="animation-duration: 1500ms; opacity: 1;">
        <div class="container pt-5 mb-5">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-12 mb-5">
                    <h3 class="heading text-center">ดำเนินการสำเร็จ</h3>
                    <br>
                    <p>
                        ขอบคุณที่เข้าร่วมกิจกรรม  MG Passion Service online Coupon
                        ระบบได้ส่ง Coupon Code ให้ท่านทาง SMS และEmail  ที่ท่านได้ลงทะเบียนเรียบร้อยแล้ว
                        โปรดตรวจสอบ Email ของท่าน
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-12 mb-5">
                    <!-- <form> -->
                    <div class="breakline"></div>
                    <div class="text-center red-txt" id="errOTP"></div>
                    <div class="btn-wrapper btn-otp">
                        <!--<a class="cta" href="javascript:;"><span>กดรับ OTP เพื่อยืนยันตัวตน</span></a>-->
                        <button id="sendOTP" type="button" class="cta" v-on:click="gotoOnlineBK()"><span>Back to
                                Online Booking</span></button>
                    </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </section>
</main>
