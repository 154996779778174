<template>

	<div id="page">
	<!-- <div id="page1" canvas="container1"> -->
    <Header />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <Footer />
  </div>

  
<div class="row d-flex d-lg-none test-drive no-gutters">
    <div class="th col-4 text-center pl-0 pr-0 nowrap">
        <a class="footer-test-drive" href="https://www.mgcars.com/th/TestDrive" role="button">ทดลองขับเอ็มจี</a>
    </div>
    <div class="th col-4 text-center pl-0 pr-0 nowrap">
        <a class="footer-find-a-showroom" href="https://www.mgcars.com/th/Dealer"
            role="button">ค้นหาผู้จำหน่ายเอ็มจี</a>
    </div>
    <div class="th col-4 text-center pl-0 pr-0 nowrap">
        <a class="footer-find-charging-station" href="https://www.mgcars.com/th/innovation/ev/charging-station"
            role="button">ค้นหาสถานีชาร์จ</a>
    </div>
    <div class="en col-4 text-center pl-0 pr-0 nowrap">
        <a class="footer-test-drive" href="https://www.mgcars.com/th/TestDrive" role="button">Test Drive</a>
    </div>
    <div class="en col-4 text-center pl-0 pr-0 nowrap">
        <a class="footer-find-a-showroom" href="https://www.mgcars.com/th/Dealer" role="button">Find a Showroom</a>
    </div>
    <div class="en col-4 text-center pl-0 pr-0 nowrap">
        <a class="footer-find-charging-station" href="https://www.mgcars.com/th/innovation/ev/charging-station"
            role="button">Find a Charging Station</a>
    </div>
</div>

<!-- <a class="btn btn-primary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
    Link with href
  </a>
  <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
    Button with data-bs-target
  </button>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div>
        Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
      </div>
      <div class="dropdown mt-3">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
          Dropdown button
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li><a class="dropdown-item" href="#">Action</a></li>
          <li><a class="dropdown-item" href="#">Another action</a></li>
          <li><a class="dropdown-item" href="#">Something else here</a></li>
        </ul>
      </div>
    </div>
  </div> -->


<div tabindex="-1"  aria-labelledby="rightMenuLabel" id="rightMenu" data-bs-backdrop="false" class="offcanvas offcanvas-end mobile-canvas container pt-5 pr-0 pb-3 pl-0">
<!-- <div class="mobile-canvas container pt-5 pr-0 pb-3 pl-0"> -->
    <ul class="nav">
        <li class="th nav-item">
            <a class="nav-link active" href="https://www.mgcars.com/th/Home" id="homeMobile">หน้าแรก</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link active" href="https://www.mgcars.com/en/Home">HOME</a>-->
<!--        </li>-->
        <li class="th nav-item dropdown">
            <a class="nav-link" href="#" id="MGModelsDropdownMobile" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                รุ่นรถ
            </a>
            <div class="dropdown-menu w-100" aria-labelledby="MGModelsDropdownMobile">
                <ul class="sub-menu modalBG" id="subModel">
                  <li><a href="https://www.mgcars.com/th/innovation/ismart">MG i-SMART</a></li>
                </ul>
            </div>
        </li>
<!--        <li class="en nav-item dropdown">-->
<!--            <a class="nav-link" href="#" id="MGModelsDropdownMobile" role="button" data-toggle="dropdown"-->
<!--                aria-haspopup="true" aria-expanded="false">-->
<!--                MG MODELS-->
<!--            </a>-->
<!--            <div class="dropdown-menu w-100" aria-labelledby="MGModelsDropdownMobile">-->
<!--                <ul class="sub-menu modalBG">-->
<!--                </ul>-->
<!--            </div>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/Promotion" id="promotionMobile">โปรโมชัน</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/Promotion">Promotion</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/AfterSalesService" id="serviceMobile">บริการหลังการขาย</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/AfterSalesService">Services</a>-->
<!--        </li>-->
        <li class="th nav-item dropdown">
            <a class="nav-link" href="#" id="MGInnovationDropdownMobile" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                นวัตกรรม
            </a>
            <div class="dropdown-menu w-100" aria-labelledby="MGInnovationDropdownMobile">
                <ul class="sub-menu">
                    <li><a href="https://www.mgcars.com/th/innovation/ismart">MG i-SMART</a></li>
                    <li><a href="https://www.mgcars.com/th/innovation/britdynamic">BRIT DYNAMIC</a></li>
                    <li><a href="https://www.mgcars.com/th/innovation/adas">ADAS</a></li>
                    <li><a href="https://www.mgcars.com/th/innovation/ev">EV INNOVATON</a></li>
                </ul>
            </div>
        </li>
<!--        <li class="en nav-item dropdown">-->
<!--            <a class="nav-link" href="#" id="MGInnovationDropdownMobile" role="button" data-toggle="dropdown"-->
<!--                aria-haspopup="true" aria-expanded="false">-->
<!--                Innovations-->
<!--            </a>-->
<!--            <div class="dropdown-menu w-100" aria-labelledby="MGInnovationDropdownMobile">-->
<!--                <ul class="sub-menu">-->
<!--                    <li><a href="https://www.mgcars.com/en/innovation/ismart">MG i-SMART</a></li>-->
<!--                    <li><a href="https://www.mgcars.com/en/innovation/britdynamic">BRIT DYNAMIC</a></li>-->
<!--                    <li><a href="https://www.mgcars.com/en/innovation/adas">ADAS</a></li>-->
<!--                    <li><a href="https://www.mgcars.com/en/innovation/ev">EV INNOVATON</a></li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </li>-->
        <li class="th nav-item dropdown">
            <a class="nav-link" href="#" id="MGNewsDropdownMobile" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                ข่าวสารและกิจกรรม
            </a>
            <div class="dropdown-menu w-100" aria-labelledby="MGNewsDropdownMobile">
                <ul class="sub-menu">
                    <li><a href="https://www.mgcars.com/th/NewsActivities" id="mobileNews">ข่าวสาร</a></li>
                    <li><a href="https://www.mgcars.com/th/EventAndRoadshow" id="mobileActivity">กิจกรรม</a></li>
                </ul>
            </div>
        </li>
<!--        <li class="en nav-item dropdown">-->
<!--            <a class="nav-link" href="#" id="MGNewsDropdownMobile" role="button" data-toggle="dropdown"-->
<!--                aria-haspopup="true" aria-expanded="false">-->
<!--                NEWS & ACTIVITIES -->
<!--            </a>-->
<!--            <div class="dropdown-menu w-100" aria-labelledby="MGNewsDropdownMobile">-->
<!--                <ul class="sub-menu">-->
<!--                    <li><a href="https://www.mgcars.com/en/NewsActivities">News</a></li>-->
<!--                    <li><a href="https://www.mgcars.com/en/EventAndRoadshow">Activities</a></li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </li>-->
        <li class="th nav-item dropdown">
            <a class="nav-link" href="#" id="MGAboutDropdownMobile" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                เกี่ยวกับเรา
            </a>
            <div class="dropdown-menu w-100" aria-labelledby="MGAboutDropdownMobile">
                <ul class="sub-menu">
                    <li><a href="https://www.mgcars.com/th/Overview" id="mobileOverview">บทนำ</a></li>
                    <li><a href="https://www.mgcars.com/th/WhyMG" id="mobileWhy">ทำไมจึงมีเอ็มจี</a></li>
                    <li><a href="https://www.mgcars.com/th/Heritage" id="mobileHeritage">ประวัติของเอ็มจี</a></li>
                </ul>
            </div>
        </li>
<!--        <li class="en nav-item dropdown">-->
<!--            <a class="nav-link" href="#" id="MGAboutDropdownMobile" role="button" data-toggle="dropdown"-->
<!--                aria-haspopup="true" aria-expanded="false">-->
<!--                About MG-->
<!--            </a>-->
<!--            <div class="dropdown-menu w-100" aria-labelledby="MGAboutDropdownMobile">-->
<!--                <ul class="sub-menu">-->
<!--                    <li><a href="https://www.mgcars.com/en/Overview">OVERVIEW</a></li>-->
<!--                    <li><a href="https://www.mgcars.com/en/WhyMG">WHY MG? </a></li>-->
<!--                    <li><a href="https://www.mgcars.com/en/Heritage">HERITAGE</a></li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/RequestBrochure" id="brochure">ขอรับโบรชัวร์</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/RequestBrochure">REQUEST A BROCHURE</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/Calculator" id="priceCal">คำนวณราคา</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/Calculator">PRICE CALCULATION</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/RequestQuotation" id="quotation">ขอรับใบเสนอราคา</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/RequestQuotation">REQUEST QUOTATION</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/TestDrive" id="testDrive">ทดลองขับ</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/TestDrive">TEST DRIVE</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/Dealer" id="dealers">ผู้จำหน่ายเอ็มจี</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/Dealer">DEALERS</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link"
                href="https://www.mgcars.com/th/NetworkExpansion" id="network">ร่วมเป็นหนึ่งในผู้จำหน่ายของเรา</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link"-->
<!--                href="https://www.mgcars.com/en/NetworkExpansion">NETWORK EXPANSION</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link"
                href="https://www.mgcars.com/th/DrivingExperienceCentre" id="expCenter">ศูนย์สร้างประสบการณ์การขับขี่รถยนต์เอ็มจี
            </a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link"-->
<!--                href="https://www.mgcars.com/en/DrivingExperienceCentre">MG DRIVING EXPERIENCE CENTRE -->
<!--            </a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/MGFleetSale" id="fleetSale">ลูกค้าองค์กร</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/MGFleetSale">MG FLEET SALES</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/ContactUs" id="contactUs">ติดต่อเรา</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/ContactUs">CONTACT US</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/Faq" id="faq">คำถามที่พบบ่อย</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/Faq">FAQ</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/Career" id="career">ร่วมงานกับเรา</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/Career">CAREER</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/PrivacyAndTerm" id="privacy">นโยบายความเป็นส่วนตัว</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/PrivacyAndTerm">PRIVACY AND TERM</a>-->
<!--        </li>-->
        <li class="th nav-item">
            <a class="nav-link" href="https://www.mgcars.com/th/Sitemap" id="siteMap">แผนผังเว็บไซต์</a>
        </li>
<!--        <li class="en nav-item">-->
<!--            <a class="nav-link" href="https://www.mgcars.com/en/Sitemap">SITE MAP</a>-->
<!--        </li>-->
    </ul>
    <ul class="nav language mt-1">
        <li class="nav-item ">
            <div class="nav-link active lang" lang="th">th</div>
        </li>
        <li class="nav-item">
            <div class="nav-link lang" lang="en">en</div>
        </li>
    </ul>
</div>
</template>

<script>

// import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import '@/assets/styles/style.scss';
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import Header  from '@/components/Header/Header.vue';
import Footer  from '@/components/Footer/Footer.vue';
import axios from "axios";
import $ from "jquery";

export default {
        components: {
            Header,
            Footer,
        },
  mounted() {
    let MenuLang = {
      homeMobile: {
        en: "HOME",
        th: "หน้าแรก"
      },
      MGModelsDropdownMobile: {
        en: "MG MODELS",
        th: "รุ่นรถ"
      },
      promotionMobile: {
        en: "PROMOTION",
        th: "โปรโมชัน"
      },
      serviceMobile: {
        en: "SERVICES",
        th: "บริการหลังการขาย"
      },
      MGInnovationDropdownMobile: {
        en: "INNOVATIONS",
        th: "นวัตกรรม"
      },
      MGNewsDropdownMobile: {
        en: "NEWS & ACTIVITIES",
        th: "ข่าวสารและกิจกรรม"
      },
      brochure: {
        en: "REQUEST A BROCHURE",
        th: "ขอรับโบรชัวร์"
      },
      priceCal: {
        en: "PRICE CALCULATION",
        th: "คำนวณราคา"
      },
      quotation: {
        en: "REQUEST QUOTATION",
        th: "ขอรับใบเสนอราคา"
      },
      testDrive: {
        en: "TEST DRIVE",
        th: "ทดลองขับ"
      },
      dealers: {
        en: "DEALERS",
        th: "ผู้จำหน่ายเอ็มจี"
      },
      network: {
        en: "NETWORK EXPANSION",
        th: "ร่วมเป็นหนึ่งในผู้จำหน่ายของเรา"
      },
      expCenter: {
        en: "MG DRIVING EXPERIENCE CENTRE",
        th: "ศูนย์สร้างประสบการณ์การขับขี่รถยนต์เอ็มจี"
      },
      fleetSale: {
        en: "MG FLEET SALES",
        th: "ลูกค้าองค์กร"
      },
      contactUs: {
        en: "CONTACT US",
        th: "ติดต่อเรา"
      },
      faq: {
        en: "FAQ",
        th: "คำถามที่พบบ่อย"
      },
      career: {
        en: "CAREER",
        th: "ร่วมงานกับเรา"
      },
      privacy: {
        en: "PRIVACY AND TERM",
        th: "นโยบายความเป็นส่วนตัว"
      },
      siteMap: {
        en: "SITE MAP",
        th: "แผนผังเว็บไซต์"
      },
      MGAboutDropdownMobile: {
        en: "ABOUT MG",
        th: "เกี่ยวกับเรา"
      },
      mobileOverview: {
        en: "OVERVIEW",
        th: "บทนำ"
      },mobileWhy: {
        en: "WHY MG?",
        th: "ทำไมจึงมีเอ็มจี"
      },mobileHeritage: {
        en: "HERITAGE",
        th: "ประวัติของเอ็มจี"
      },mobileNews: {
        en: "News",
        th: "ข่าวสาร"
      },mobileActivity: {
        en: "Activities",
        th: "กิจกรรม"
      }
    }
    function ChengMenuLang() {
      for(let menuId in MenuLang) {
        $(`#${menuId}`).text(`${MenuLang[`${menuId}`][`${sessionStorage.LANG}`]}`);
      }
    }
    if(!sessionStorage.LANG){
      sessionStorage.setItem('LANG','th')
      sessionStorage.setItem('OLD_LANG','th')
    }else{
      // $('.lang.active').removeClass('active')
      // $(`.lang[lang='${sessionStorage.LANG}']`).addClass('active')
      ChengMenuLang()
    }

    // $('.th,.en').hide()
    console.log((sessionStorage.LANG))
    $('.lang').on('click',async (evt)=>{
      sessionStorage.setItem('LANG',evt.currentTarget.innerHTML)
      ChengMenuLang()
      let r = await axios.get(`https://onlinebookingapi.mgcars.com/v1/cartype/menu`, {headers: {
          "lang": sessionStorage.LANG
        }})
      let data = r.data;
      if (data.header.res_code == 200) {
        if (data.body.error) resolve(data.body.error.message)
        if (data.body.length) {
          $("#subModel").html('')
          for (const i of data.body) {
            $("#subModel").append(`<li><a href="${i.link}">${i.name}</a></li>`)
          }
          return resolve(true)
        }
        return resolve(false)
      }
      // location.reload()
    })
  },

  async created() {
    function getCarModels() {
      // token,pin
      return new Promise(async (resolve, reject) => {
        let r = await axios.get(`https://onlinebookingapi.mgcars.com/v1/cartype/menu`, {headers: {
            "lang": sessionStorage.LANG
          }})
        let data = r.data;
        if (data.header.res_code == 200) {
          if (data.body.error) resolve(data.body.error.message)
          if (data.body.length) {
            $("#subModel").html('')
            for (const i of data.body) {
              $("#subModel").append(`<li><a href="${i.link}">${i.name}</a></li>`)
            }
            return resolve(true)
          }
          return resolve(false)
        } else return resolve(data.header.res_desc)
      })
    }
    await getCarModels()
  }
}
</script>
<style lang="scss">
// #select2-title-results > li.select2-results__option.select2-results__option--selectable.select2-results__option--selected.select2-results__option--highlighted{
// 	background-color: #BE3633 !important;
// }
#select2-title-results li{
  font-size: 20px;
  padding-left: 15px;
}
#select2-title-results > li.select2-results__option.select2-results__option--highlighted.select2-results__option--selectable{
	background-color: #BE3633 !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
	background-color: #BE3633 !important;
}
li.select2-results__option.select2-results__option--selectable{
  font-size: 20px;
  padding-left: 23px;
}
.text-right{
  text-align: right;
}
#select2--results > li{
  font-size: 20px;
  padding-left: 24px;
}
#select2--results > li.select2-results__option.select2-results__option--selectable.select2-results__option--highlighted{
  background-color: #BE3633 !important;
}

// ==================

.openRmenu{
    transition-duration: 420ms;
    transform: translate(-243px, 0px);
}
.closeRmenu{
    transition-duration: 300ms;
    transform: translate(0px, 0px);
}
#rightMenu{
    overflow-y: scroll;
  .dropdown-menu{
      border: none;
  }
  ul li a:hover {
      color: #be3633;
  }
  a{
      text-decoration: none
  }
    .mobile-canvas .sub-menu a {
        color: #232323;
        padding: 0.35em 2em;
        position: relative;
        display: block;
        text-transform: none;
        font-weight: 300;
    }
  
}
.mobile-canvas div.nav-link {
    color: #232323;
    padding: 0.8em 2em;
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 15px;
}
.mobile-canvas .language .nav-item:last-child div.nav-link{
    padding-left: 11.5px;
}

@media (max-width: 991.98px){
    #rightMenu {
        width: 256px !important;
        height: 100%;
        top: 0;
        right: 0;
    }
}
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
