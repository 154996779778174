<template src="./Fail.html"></template>

<style src="../../assets/styles/custom.css" scoped lang="css"></style>
<style src="../../assets/styles/style.css" scoped lang="css"></style>
<script>

// import '@/assets/styles/custom.css';
import '@/assets/lib/select2/select2.min.css';
import '@/assets/lib/select2/select2.min.js';
import $ from 'jquery';
export default {
  name: 'Fail',
  components: {
  },
  mounted() {
  },
  methods: {
    gotoOnlineBK () {
      location.href = 'https://onlinebooking.mgcars.com/'
    },
  }
}
</script>
<style src="./Fail.scss" scoped lang="scss"></style>
