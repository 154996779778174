<template src="./Home.html"></template>
<style src="../../assets/styles/custom.css" scoped lang="css"></style>
<style src="../../assets/styles/style.css" scoped lang="css"></style>
<script>

import $ from 'jquery';
import axios from "axios";
export default {
  name: 'Home',
  components: {
  },
  async mounted() {
    function getLatestCondition() {
      return new Promise(async(resolve, reject) => {
        let r = await axios.get(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/condition/latest`, {headers: {
            "lang": sessionStorage.LANG
          }})
        if(r.data.header.res_code == 200 && r.data.body.length > 0) {
          updateConditionImage(r.data.body[0].image);
          // r.data.body[0].description = r.data.body[0].description.replaceAll("<div")
          updateConditionText(r.data.body[0].description);
        }
        return resolve(r.data);
      })
    }

    function setScopeRecursively(el, scopeString) {
      const nodes = el.children
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if (node.dataset[scopeString] !== '') {
          node.dataset[scopeString] = ''
        }
        if (node.children.length > 0) {
          setScopeRecursively(node, scopeString)
        }
      }
    }

    function updateConditionImage(link) {
      $("#cond-img").attr("src", link)
    }

    function updateConditionText(desc) {
      $("#cond-desc").html(desc)
    }

    await getLatestCondition()
  },
  methods: {
    gotoOnlineBK () {
      location.href = 'https://stonlinebooking.mgcars.com/'
    },
    async gotoRegister() {
      let r = await axios.get(`${process.env.VUE_APP_ECOUPON_DOMAIN}/v1/campaign/now`, {headers: {
          "lang": sessionStorage.LANG
        }})
      if(r.data.header.res_code == 200 && r.data.body) {
        this.$router.push('register');
      } else {
        this.$router.push('fail');
      }
    },
    showError(message) {
      $("#err_text").html(`${message}`)
      $("#error-modal").modal('show')
    }
  }
}
</script>
<style src="./Home.scss" scoped lang="scss"></style>
