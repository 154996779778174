<main class="bg-showroom" data-aos="fade">
    <section id="promotion"  class="animsition" data-animsition-in-class="fade-in-up">
        <div class="container pt-3">
            <div class="row">
                <!-- <div class="col-12 pt-4"><h3 class="heading text-center">เลือกโปรโมชัน</h3></div> -->
                <div class="col-12">
                    <div class="tab-content-wrap">
                        <div class="tab-content active" id="tab1">
                            <img id="cond-img" src="" alt="credit">
                            <div class="promotion-detail-wrap">
                                <div class="row no-gutters">
                                    <div class="col-12 mt-4">
                                        <div id="cond-desc" class="condition" style="word-break: break-word !important;">
<!--                                            <h4>warut</h4>-->
<!--                                            <ul>-->
<!--                                                <li><span style="font-size:14px">ฟรีชุดพรมปูพื้นมูลค่า 705 บาท</span></li>-->
<!--                                                <li><span style="font-size:14px">เงื่อนไขทั้งหมดสำหรับลูกค้าที่จอง และชำระเงินจอง มูลค่า 5,000 บาท ให้กับผู้จำหน่ายรถยนต์เอ็มจีอย่างเป็นทางการผ่านช่องทาง https://onlinebooking.mgcars.com/ เท่านั้น ภายในวันที่ 1&nbsp;มิถุนายน 2564&nbsp;ถึง 30 มิถุนายน 2564 และรับรถยนต์ภายใน 31&nbsp;กรกฏาคม&nbsp;2564&nbsp; เท่านั้น</span></li>-->
<!--                                                <li><span style="font-size:14px">เงื่อนไขทั้งหมดไม่รวมภาษีมูลค่าเพิ่ม และไม่สามารถแลกเปลี่ยนเป็นเงินสดได้</span></li>-->
<!--                                                <li><span style="font-size:14px">ติดต่อสอบถามข้อมูล ณ โชว์รูมเอ็มจี ทั่วประเทศ</span></li>-->
<!--                                            </ul>-->
                                        </div>
                                    </div>
                                    <!--div class="col-12 mt-2 mb-0">
                                        <p class="ps mt-2 mb-0" style="font-size: 16px;">หมายเหตุ : การเลือกโปรโมชันในหน้านี้มิได้มีข้อผูกมัดใดๆ ในทางกฎหมาย ผู้ซื้อสามารถทำข้อตกลงใหม่ เกี่ยวกับโปรโมชันได้ที่ผู้แทนจำหน่าย MG อย่างเป็นทางการที่ท่านเลือก </p>
                                    </div-->
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="tab2">
                            <div class="promotion-detail-wrap">
                                
                                <div class="row">
                                    <div class="col-12">
                                        <h4 class="text-dark text-center">เงินสด</h4>
                                        <div class="check"><img src="@/assets/images/tick.svg"></div>
                                    </div>
                                    <div id="cashDesc" class="col-lg-8 offset-lg-2 col-12 mt-3 condition">
                                        <h4 class="text-center">ประกันภัยชั้นหนึ่ง 1 ปี</h4>
                                        <ul>
                                            <li>รับประกันคุณภาพรถใหม่ <span>ขยายเวลารับประกัน 3 ปี เป็น 5 ปี หรือ 150,000 km</span> (เเล้วเเต่ระยะใดถึงก่อน)</li>
                                            <li><span class="text-darkred text-underline">ฟรีค่าเเรง</span>เช็คระยะ 10 ครั้ง หรือ ภายในระยะ 5 ปี (เเล้วเเต่ระยะใดถึงก่อน)</li>
                                        </ul>
                                    </div>
                                    <!--div class="col-12 mt-2 mb-0">
                                        <p class="ps mt-2 mb-0" style="font-size: 16px;">หมายเหตุ : การเลือกโปรโมชันในหน้านี้มิได้มีข้อผูกมัดใดๆ ในทางกฎหมาย ผู้ซื้อสามารถทำข้อตกลงใหม่ เกี่ยวกับโปรโมชันได้ที่ผู้แทนจำหน่าย MG อย่างเป็นทางการที่ท่านเลือก </p>
                                    </div-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="breakline"></div>
                </div>
                <div class="col-12 p-0 model-pagination d-flex justify-content-center">
                    <div class="btn-wrapper">
                        <a class="cta animsition-link" v-on:click="gotoOnlineBK()"  data-animsition-out-class="fade-out-down"><span>ย้อนกลับ</span></a>
                    </div>
                    <div class="btn-wrapper">
                        <a id="nextBT" class="cta animsition-link"  v-on:click="gotoRegister()"><span>ถัดไป</span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="modal center get-otp" id="error-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-vertical-centered" role="document">
            <div class="modal-content">
                <div class="close-wrap"><button type="button" class="close xBT" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">X</span></button></div>
                <div class="modal-body text-center">
                    <p id="err_text"></p>

                    <!--          <div class="text-center red-txt" id="alerttxt"></div>-->
                    <div class="btn-wrapper">
                        <!--                            <a class="cta" href="javascript:void(0)"  data-bs-dismiss="modal" aria-label="Close" v-on:click="$router.push('success')"><span>ตกลง</span></a>-->
                        <a class="cta" href="javascript:void(0)"  data-bs-dismiss="modal" aria-label="Close" ><span>ตกลง</span></a>
                    </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</main>
