import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'
import Home from '../components/Home/Home.vue'
import Register from '../components/Register/Register.vue'
import Success from '../components/Success/Success.vue'
import Fail from '../components/Fail/Fail.vue'
import FailCoupon from '../components/FailCoupon/FailCoupon.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/fail',
    name: 'Fail',
    component: Fail
  },
  {
    path: '/fail-coupon',
    name: 'FailCoupon',
    component: FailCoupon
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
